<template>
  <div class="">
    <div class="uk-box-shadow-medium uk-background-default uk-padding-small brd12">
      <div class="uk-grid uk-child-width-1-4@l uk-child-width-1-3@m">
        <div>
          <div class="uk-text-left uk-margin-small-bottom fnt-bld">
            Категория
          </div>
            <div>
              <select
                class="uk-select"
                v-model="filter.category"
                @change="filterListVideos"
                >
                <option disabled :value="[]">Категория</option>
                <template v-for="(category, index) in categories" :key="index">
                  <option
                  :value="[category.id_category]"
                  >
                    {{category.category_name}}
                  </option>
                </template>
              </select>
            </div>
        </div>

        <div>
          <div>
            <div class="uk-text-left uk-margin-small-bottom fnt-bld">
              Дата
            </div>
            <div  class="uk-grid uk-grid-small uk-child-width-1-2">
              <div>
                <input
                  class="uk-select"
                  v-model="filter.date[0]"
                  @change="filterDate"
                  type="date" data-uk-datepicker="{format:'DD.MM.YYYY'}">
              </div>
              <div>
                <input
                  class="uk-select"
                  v-model="filter.date[1]"
                  @change="filterDate"
                  type="date" data-uk-datepicker="{format:'DD.MM.YYYY'}">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="uk-background-default uk-padding-small uk-margin brd12" v-show="statusLoadData === 2">
      <div class="">
        Ничего не найдено...
      </div>
    </div>
    <div class="uk-position-relative" v-show="statusLoadData !== 2">
      <div class="uk-grid uk-grid-small uk-child-width-1-2@s uk-child-width-1-3@m uk-child-width-1-4@l" ref="list">
        <div
          v-for="(video, i) in listVideos" :key="i"
        >
        <div class="">
          <div
            @click="navigatePage(`/video/${video.video_id}`)"
            class="uk-box-shadow-medium uk-background-default uk-margin-top brd12 cr-pointer video-item"
            >

            <div class="uk-grid uk-grid-small">
              <div class="video-item_prew uk-width-1-1">
                <img :src="`https://img.youtube.com/vi/${video.youtube_id}/0.jpg`" alt="">
              </div>
              <div class="uk-width-1-1 uk-text-left video-item_data">
                <div class="uk-padding-small">
                  <div class="fs18@m fs16@s fs14 fnt-med uk-height-xsmall@s">
                    {{ video.video_name }}
                  </div>
              <!--    <div class="fs16@m fs12 uk-margin-small-top">
                    {{ video.video_desc }}
                  </div> -->

                  <div class="video-item_footer">
                    <div class="uk-grid uk-grid-small uk-margin-small uk-flex-between">
                      <div>
                        <div class="fs14@s fs12  cl-grey2">
                          г. {{ video.city }}
                        </div>
                      </div>
                      <div>
                        <div class="uk-margin-left fs14@s fs12 cl-grey2 uk-margin-right">
                          {{ dateString(video.date_event) }}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>

      </div>
      </div>

      <div class="uk-padding-small"  v-show="statusLoadData === 1">
        <img src="/images/wait.svg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        listVideos: [],
        categories: [],
        statusLoadData: 0,
        last_page: 0,
        filter: {
          date: [],
          category: [],
          page: 1,
        }
      }
    },

    created() {

      this.filterListVideos()

      window.addEventListener('scroll', (e) => {
        const CLIENT_RECT = document.getElementById('container__main').getBoundingClientRect()
      //  const STICK = CLIENT_RECT.height === 0 ? window.innerHeight - 0: window.innerHeight

        let SPACING = CLIENT_RECT.bottom - window.innerHeight

        if (SPACING - 50 <= 0 && this.statusLoadData !== 1) {
          this.filter.page++
          if (this.filter.page > this.last_page) {
            return
          }
          this.filterListVideos();
        }
      })

      this.$api.post('video/get_cats').then(rs => {
        this.categories = rs.data
      })
    },

    methods: {
      filterListVideos() {
        this.statusLoadData = 1
//        this.listVideos = []

        this.$api.post('video/filter', {
          filter: this.filter,
        })
        .then(rs => {

          this.last_page = rs.data.last_page
          if (rs.data.data.length) {
            this.statusLoadData = 0
            rs.data.data.forEach(video => {
              this.listVideos.push(video)
            })
          } else {
            this.statusLoadData = 2
          }
        })
      },

      dateString(value) {
        let dates = [
          'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля',
          'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'
        ]

        let date = new Date(value)
        return `${date.getUTCDate()} ${dates[date.getUTCMonth()]} ${date.getUTCFullYear()} г.`
      },

      navigatePage(src) {
        window.open(src)
      },

      filterDate() {
        if (this.filter.date.length === 2) {
          if (this.filter.date[0] && this.filter.date[1]) {
            this.filterListVideos()
          }
        }
      }
    }
  }
</script>


<style scoped>
  .video-item:hover {
    transition: 0.4s;
    opacity: 0.8
  }


  .video-item_prew img {
     border-top-left-radius:12px;
     border-top-right-radius:12px
  }

  @media (max-width: 960px) {
    .video-item_footer {
      position: static !important
    }
  }
</style>
